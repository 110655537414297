import { Urls } from "../models/enums/urls";

enum HitType {
    pageview = "pageview",
    screenview = "screenview",
    event = "event",
    transaction = "transaction",
    item = "item",
    social = "social",
    exception = "exception",
    timing = "timing",
}

export enum AnalyticsEventCategory {
    Request = "Request",
    View = "View",
    ToDashboard = "ToDashboard"
}

interface GoogleAnalyticsOptions {
    trackingId?: string;
    cookieDomain?: string;
    name?: string;
    hitType?: HitType;
}

declare function ga(
    command: string,
    options: GoogleAnalyticsOptions | HitType,
    data1?: string,
    data2?: string,
    data3?: string,
    eventValue?: number
);

export class Analytics {
    private static addScript(i, s, o, g, r): void {
        i["GoogleAnalyticsObject"] = r;
        i[r] = i[r] || function() { (i[r].q = i[r].q || []).push(arguments); }, i[r].l =
            1 * new Date().getMilliseconds();
        const a = s.createElement(o);
        const m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    }

    static init() {
        Analytics.addScript(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");

        ga("create", {
            trackingId: "UA-74117666-2",
            cookieDomain: "auto",
        });

        ga("send", {
            hitType: HitType.pageview,
        });
    }

    static event(category: AnalyticsEventCategory, action: string, label?: string, value?: number): void {
        if (!action.includes(Urls.notifications)) {
            ga("send", HitType.event, category.toString(), action, label, value);
        }
    }
}
