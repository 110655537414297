import { C } from "../../common/component";
import { Urls } from "../../models/enums/urls";
import { Requester } from "../../services/requester";
import { StorageHelper, StorageItem } from "../../common/storage-helper";

export class ResetPassword {
    static async load(token: string) {
        const container = C.div.addClass("container login").appendTo(C.body);
        const form = C.form.addClass("login-form").appendTo(container);
        C.img.attr("src", "images/logo.svg").attr("alt", "Wemply_official_logo")
            .attr("height", "35px").attr("width", "auto").appendTo(form);

        let translations: any;
        try {
            translations = await Requester.get(Requester.getUrl(Urls.authenticationResetPasswordTranslations, {token: token}));
        } catch (err) {
            C.h4.text(err.responseText).appendTo(form);
        }

        const getModernFormControl = (label: string, placeholder: string) => {
            const formControlWrap = C.div.addClass("form-control-wrap").appendTo(form);
            // C.label.addClass("control-label").text(label).appendTo(formControlWrap);
            return C.input.addClass("form-control")
                .attr("placeholder", placeholder)
                .attr("aria-label", placeholder)
                .appendTo(formControlWrap);
        };

        const firstControl = getModernFormControl(null, translations.newPassword)
            .attr("type", "password")
            .attr("name", "password");
        const secondControl = getModernFormControl(null, translations.repeatPassword)
            .attr("type", "password")
            .attr("name", "repeatPassword");
        C.button.addClass("btn btn-lg btn-primary btn-block").attr("type", "submit").text(translations.save)
            .appendTo(form);
        const errorMessage = C.h4.hide().appendTo(form);

        form.submit(() => {
            const newPasswordValue = <string>firstControl.val();
            const confirmNewPasswordValue = <string>secondControl.val();
            if (newPasswordValue && confirmNewPasswordValue) {
                if (newPasswordValue === confirmNewPasswordValue) {
                    this.reset(newPasswordValue, token, errorMessage);
                } else {
                    errorMessage.show().text(translations.passwordsDontMatch);
                }
            } else {
                errorMessage.show().text(translations.fillFields);
            }
            return false;
        });
    }

    private static async reset(newPassword: string, token: string, errorMessage: JQuery) {
        try {
            const authControlToken = await Requester.get(Requester.getUrl(Urls.authenticationResetPassword,
                {token: token, password: newPassword}));
            StorageHelper.set(StorageItem.authControlToken, authControlToken);
            window.location.replace(window.location.href.split("?")[0] + window.location.hash);
        } catch (err) {
            errorMessage.show().text(err.responseText);
        }
    }
}
