import { Urls } from "../models/enums/urls";
import { Requester } from "../services/requester";
import { StorageHelper, StorageItem } from "./storage-helper";

export class PushNotifications {
    static async unsubscribeAndGetEndpoint(): Promise<string> {
        const registration = await navigator.serviceWorker.getRegistration();
        const subscription = await registration?.pushManager.getSubscription();
        StorageHelper.remove(StorageItem.arePushNotificationsConfigured);
        return subscription?.endpoint;
    }

    static async ensureRegistration() {
        if (!("serviceWorker" in navigator) || !("Notification" in window)) {
            return;
        }
        const registration = await navigator.serviceWorker.getRegistration();
        if (!registration) {
            return null;
        }
        if (!StorageHelper.get(StorageItem.arePushNotificationsConfigured)) {
            let subscription = await registration.pushManager.getSubscription();
            if (subscription != null) {
                await this.register(subscription);
            } else if (Notification.permission !== "denied") {
                if (await Notification.requestPermission() !== "denied") {
                    try {
                        subscription = await registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: "BLuQcR-b_VUVJ3cZtWVzqHC1shs4Dq9pj4EtdjtdtTDYQGSKk4BVDBLMWbb5pL9cO_qYpf8qOuagDQqK-Dcw2dg",
                        });
                    } catch {
                    }
                    if (subscription != null) {
                        await this.register(subscription);
                    }
                }
            }
        }
    }

    private static async register(subscription: PushSubscription) {
        await Requester.send(Requester.getUrl(Urls.registerWebPushNotifications, {
            endpoint: subscription.endpoint,
            auth: PushNotifications.arrayBufferToBase64(subscription.getKey("auth")),
            p256dh: PushNotifications.arrayBufferToBase64(subscription.getKey("p256dh"))
        }));
        StorageHelper.set(StorageItem.arePushNotificationsConfigured, true);
    }

    private static arrayBufferToBase64(buffer) {
        let binary = "";
        const bytes = new Uint8Array(buffer);
        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}
