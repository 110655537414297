import { Requester } from "./requester";

export let Txt: BaseTranslation;

export async function TxtInitialize(language: string): Promise<any> {
    Txt = await Requester.get(Requester.getUrl("public/translations", {language: language}));
}

interface BaseTranslation {
    monthNames: string[];
    dayNamesShort: string[];

    downloadApp: string;
    add: string;
    addComment: string;
    filters: string;
    areYouSure: string;
    browse: string;
    cancel: string;
    comment: string;
    change: string;
    changeData: string;
    changeForm: string;
    changePassword: string;
    changeRowComment: string;
    choose: string;
    chooseEmployees: string;
    chooseLocation: string;
    clear: string;
    close: string;
    confirmDeleteDemoData: string;
    confirmNewPassword: string;
    copy: string;
    deleteDemoData: string;
    demoDataDeleted: string;
    displayed: string;
    edit: string;
    employee: string;
    endLocation: string;
    fillFields: string;
    hidden: string;
    hideShortcuts: string;
    imagePreview: string;
    locationChosen: string;
    locationOnMap: string;
    logout: string;
    moreUppercase: string;
    nameless: string;
    networkError: string;
    newPassword: string;
    no: string;
    noMessages: string;
    noRecords: string;
    notifications: string;
    objectLocation: string;
    oldPassword: string;
    onlyJpg: string;
    over25MB: string;
    passChanged: string;
    password: string;
    passwordsDontMatch: string;
    paste: string;
    profile: string;
    project: string;
    projectLocation: string;
    repeatPassword: string;
    save: string;
    searchLocation: string;
    search: string;
    selectAll: string;
    selectLocation: string;
    showAll: string;
    showShortcuts: string;
    someError: string;
    startLocation: string;
    startLogging: string;
    stopLogging: string;
    invalidValueInsertedInTimesheet: string;
    cannotInsertThisShortcutToThisRowInTimesheet: string;
    cannotInsertWorkToThisRowInTimesheet: string;
    toDashboard: string;
    unselectAll: string;
    mainData: string;
    vactionAndShiftLegend: string;
    yes: string;
    zoomIn: string;
    zoomOut: string;
    today: string;
    year: string;
    month: string;
    week: string;
    day: string;
    employeeBasedCalendar: string;
    projectBasedCalendar: string;
    allDayText: string;
    rangeYesterday: string;
    rangeToday: string;
    rangeTomorrow: string;
    rangeLast7days: string;
    rangeNext7days: string;
    rangeLast30days: string;
    rangeLastMonth: string;
    rangeCurrentMonth: string;
    rangeNextMonth: string;
    abbreviationHelp: string;
    timesheetEditWarning: string;
    fileUploadSuccessful: string;
    fileUploadFailed: string;
    pleaseTryAgain: string;
    fileUploadNotFinished: string;
    radius: string;
    clearLocation: string;
    chooseDesk: string;
    confirmDeskSelection: string;
    removeDeskBooking: string;
    continueWithoutChoosingDesk: string;
    youCurrentlySeeDemoData: string;
    youCurrentlySeeOwnData: string;
    showDemoData: string;
    showOwnData: string;
    remove: string;
    waitingGPS: string;
}
