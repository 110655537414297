export enum Urls {
    authenticationLogIn = "authentication/logIn",
    authenticationLogOut = "authentication/logOut",
    authenticationShouldLogOut = "authentication/shouldLogOut",
    authenticationSendResetEmail = "authentication/sendResetEmail",
    authenticationResetPasswordTranslations = "authentication/ResetPasswordTranslations",
    authenticationResetPassword = "authentication/ResetPassword",

    navbar = "common/NavigationBar",
    deleteDemoData = "common/deleteDemoData",
    registerWebPushNotifications = "common/RegisterWebPushNotifications",
    notifications = "common/notifications",
    markNotificationsAsSeen = "common/markNotificationsAsSeen",

    itemPage = "items/ItemPage",
    timerState = "timer/State",
    seatingPlan = "seating/plan",
    uploadUrl = "files/uploadUrl"
}
