import { StorageHelper, StorageItem } from "./storage-helper";

export class DomainHelper {

    static getDomain(): string { return this.getDomainWithWildcardDistinction()["0"]; }

    static isWildcardDomain(): boolean { return this.getDomainWithWildcardDistinction()["1"]; }

    private static getDomainWithWildcardDistinction(): [string, boolean] {
        const host = window.location.hostname;
        if (host === "user.wemply.com" || host === "test.wemply.com") {
            return [StorageHelper.get(StorageItem.domain), false];
        }
        const parts = host.split(".");
        if (parts.length === 0 || !parts[0]) {
            return [StorageHelper.get(StorageItem.domain), false];
        }
        if (parts.length === 3 && parts[1] === "wemply" && parts[2] === "com") {
            return [parts[0], true];
        }
        if (parts.length === 4 && parts[1] === "test" && parts[2] === "wemply" && parts[3] === "com") {
            return [parts[0], true];
        }
        return [StorageHelper.get(StorageItem.domain), false];
    }
}
