import { Urls } from "../models/enums/urls";
import { Requester } from "../services/requester";
import { PushNotifications } from "./push-notifications";
import { StorageHelper, StorageItem } from "./storage-helper";

export class AuthenticationHandler {
    static readonly controlTokenParamName = "wct";

    static async isLoggedIn(): Promise<boolean> {
        if (StorageHelper.get(StorageItem.authControlToken)) {
            try {
                const shouldLogout = <boolean>await Requester.get(Urls.authenticationShouldLogOut);
                if (shouldLogout) {
                    await this.logout();
                    return false;
                }
            } catch (e) {
                return false;
            }
            return true;
        }
        return false;
    }

    static async logIn(userName, password, successCallback, serverErrorCallback, credentialsErrorCallback, language) {
        try {
            const newAuthControlToken = await Requester.get(Requester.getUrl(Urls.authenticationLogIn,
                { userName: userName, password: password, language: language }));
            StorageHelper.set(StorageItem.authControlToken, newAuthControlToken);
            successCallback();
        } catch (err) {
            if (err.status === 401) {
                credentialsErrorCallback(err.responseText);
            } else {
                serverErrorCallback();
            }
        }
    }

    static async logInWithUrl(logInUrl: string) {
        try {
            const newAuthControlToken = await Requester.get(logInUrl);
            StorageHelper.set(StorageItem.authControlToken, newAuthControlToken);
            window.location.replace(window.location.href.split("?")[0]);
        } catch (err) {}
    }

    static async logout() {
        const endpoint = await PushNotifications.unsubscribeAndGetEndpoint();
        StorageHelper.remove(StorageItem.authControlToken);
        await Requester.send(Requester.getUrl(Urls.authenticationLogOut, {pushToken: endpoint}));
    }
}
