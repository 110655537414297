import { C } from "../common/component";
import { Icon } from "../models/enums/icon";

export class IconHelper {
    static getIconClass(icon) {
        return `fas fa-${icon}`;
    }

    static addIcon(element, icon) {
        element.addClass(IconHelper.getIconClass(icon));
    }

    static addColorfulIcon(element, icon, color) {
        element.addClass(IconHelper.getIconClass(icon)).css("color", color);
    }

    static addIconWithText(element, icon: Icon, text: string) {
        element.text(" " + text);
        element.prepend(IconHelper.getIcon(icon));
    }

    static getIconColorful(icon, color) {
        return C.i.addClass(IconHelper.getIconClass(icon)).css("color", color);
    }

    static getIcon(icon: Icon) {
        return C.i.addClass(IconHelper.getIconClass(icon));
    }
}
